<template>
    <v-form class="license-type-form">
        <v-alert
          v-if="!_.isNil(summaryError)"
          dense
          type="error"
        >
            {{ summaryError }}
        </v-alert>

        <v-switch
          v-model="form.active"
          label="Активность"
        ></v-switch>

        <v-text-field 
            v-model="$v.form.code.$model"
            :error-messages="getErrors('form.code', {
                pattern: 'Неверный код'
            })"
            label="Символьный код" 
            name="code" 
            type="text" 
            placeholder=" "
            autofocus="autofocus" 
            color="light-green" 
        ></v-text-field>

        <v-text-field 
            v-model="$v.form.name.$model"
            :error-messages="getErrors('form.name')"
            label="Название" 
            name="name" 
            type="text" 
            placeholder=" "
            color="light-green" 
        ></v-text-field>

        <v-select
          v-model="$v.form.product.$model"
          :error-messages="getErrors('form.product')"
          required
          :items="products"
          label="Продукт"
        ></v-select>

        <v-divider class="my-4" />

        <v-row justify="start" align="center" class="pb-4">
            <v-col>
                <div class="text-caption">Параметры лицензии</div>
            </v-col>
        </v-row>

        <v-row justify="start" align="center" class="pb-4">
            <v-col cols="12">
                <v-text-field 
                    v-model="$v.form.data.grade_groups.$model"
                    :error-messages="getErrors('form.data.grade_groups', {
                        pattern: 'Неверное значение. Укажите число больше нуля или оставьте поле пустым.'
                    })"
                    label="Кол-во класов в параллели" 
                    name="grade_groups" 
                    type="text" 
                    placeholder=" "
                    color="light-green" 
                ></v-text-field>
            </v-col>
        </v-row>

        <v-row justify="start" align="center" class="pb-4">
            <v-col cols="12">
                <v-text-field 
                    v-model="$v.form.data.group_students.$model"
                    :error-messages="getErrors('form.data.group_students', {
                        pattern: 'Неверное значение. Укажите число больше нуля или оставьте поле пустым.'
                    })"
                    label="Кол-во учеников в классе" 
                    name="group_students" 
                    type="text" 
                    placeholder=" "
                    color="light-green" 
                ></v-text-field>
            </v-col>
        </v-row>

        <v-row justify="start" align="center" class="pb-4">
            <v-col cols="12">
                <v-text-field 
                    v-model="$v.form.data.total_students.$model"
                    :error-messages="getErrors('form.data.total_students', {
                        pattern: 'Неверное значение. Укажите число больше нуля или оставьте поле пустым.'
                    })"
                    label="Кол-во учеников в школе" 
                    name="total_students" 
                    type="text" 
                    placeholder=" "
                    color="light-green" 
                ></v-text-field>
            </v-col>
        </v-row>

        <v-row justify="start" align="center" class="pb-4">
            <v-col cols="12">
                <v-switch
                    v-model="form.data.include_excepted_options"
                    label="Разрешить использовать исключенные варианты"
                    color="light-green"
                ></v-switch>
            </v-col>
        </v-row>

        <v-row justify="start" align="center">
            <v-col cols="12">
                <v-subheader class="py-1 px-0">Предметы и классы</v-subheader>
            </v-col>
        </v-row>

        <div>
            <v-card
                v-for="(subject, i) in form.data.subjects"
                :key="i"
                elevation="4"
                class="mb-4"
            >
                <v-card-title>
                    <diV>
                        <v-checkbox
                            v-model="subject.selected"
                            :color="$const.color.primary"
                        ></v-checkbox>
                    </diV>
                    <div>{{ subject.value }}</div>
                </v-card-title>

                <v-card-text :class="{'inactive': !subject.selected}">
                    <div>
                        <div v-for="grade in subject.grades" :key="grade.value" class="my-6 d-flex flex-row justify-start align-center">
                            <div class="mr-4">
                                <v-checkbox
                                    v-model="grade.selected"
                                    :color="$const.color.primary"
                                    hide-details
                                    :label="`${grade.value} кл.`"
                                    class="dense"
                                ></v-checkbox>
                            </div>
                            <div class="ml-4 mr-8">
                                <v-checkbox
                                    :value="grade.options === '*' ? true : false"
                                    :true-value="true"
                                    :false-value="false"
                                    :input-value="grade.options === '*' ? true : false"
                                    :color="$const.color.primary"
                                    hide-details
                                    label="Все варианты"
                                    class="dense"
                                    @change="onGradeAllOptionsChange(grade)"
                                ></v-checkbox>
                            </div>
                            <div>
                                <v-btn-toggle
                                    v-model="grade.options"
                                    dense
                                    dark
                                >
                                    <v-btn :value="5" :color="$const.color.primary">5</v-btn>
                                    <v-btn :value="10" :color="$const.color.primary">10</v-btn>
                                    <v-btn :value="15" :color="$const.color.primary">15</v-btn>
                                    <v-btn :value="20" :color="$const.color.primary">20</v-btn>
                                    <v-btn :value="25" :color="$const.color.primary">25</v-btn>
                                </v-btn-toggle>
                            </div>
                            <div v-if="grade.options !== '*'" class="flex-grow-1" :class="{'inactive': !grade.selected}">
                                <v-slider
                                    v-model="grade.options"
                                    :color="$const.color.primary"
                                    :track-color="$const.color.primary"
                                    :thumb-color="$const.color.primary"
                                    dense
                                    :min="1"
                                    :max="getMaxOption(subject.value, grade.value)"
                                    :thumb-size="24"
                                    thumb-label="always"
                                    step="1"
                                    ticks
                                    hide-details
                                >
                                    <template v-slot:append>
                                        <v-subheader class="pa-0 ma-0 text-no-wrap lh-1">
                                            Варианты
                                        </v-subheader>
                                    </template>
                                </v-slider>
                            </div>
                        </div>
                    </div>

                    <v-divider class="mx-4"></v-divider>

                    <div class="py-4">
                        <v-btn 
                            dense 
                            small 
                            dark 
                            :color="$const.color.primary" 
                            @click.prevent.stop="selectAllSubjectGrades(subject)"
                        >
                            Выбрать все классы
                        </v-btn>
                        <v-btn 
                            dense 
                            small 
                            dark 
                            :color="$const.color.primary" 
                            class="ml-2"
                            @click.prevent.stop="selectAllSubjectGrades(subject, true)"
                        >
                            Выбрать все классы + все варианты
                        </v-btn>
                        <v-btn 
                            dense 
                            small 
                            dark 
                            :color="$const.color.primary" 
                            class="ml-2"
                            @click.prevent.stop="selectAllSubjectGrades(subject, true, 15)"
                        >
                            Выбрать все классы + 15 вариантов
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </div>

        <v-row justify="start" align="center">
            <v-col cols="12">
                <v-subheader class="py-1 px-0">Продолжительность</v-subheader>
            </v-col>
            <v-col sm="2">
                <v-select
                  v-model="$v.form.data.duration.value.$model"
                  :error-messages="getErrors('form.data.duration.value')"
                  required
                  :items="duration.values"
                  dense
                ></v-select>
            </v-col>
            <v-col sm="2">
                <v-select
                  v-model="$v.form.data.duration.unit.$model"
                  :error-messages="getErrors('form.data.duration.unit')"
                  required
                  :items="duration.units"
                  dense
                ></v-select>
            </v-col>
        </v-row>

        <v-divider class="my-4" />

        <action-buttons 
            :waiting-save-and-back="waiting.save.back"
            :waiting-save-and-update="waiting.save.update"
            @back="back"
            @save="save"
        />
    </v-form>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import ActionButtons from '@/components/crud/ActionButtons.vue'

const MAX_OPTION = 50;

export default {
    name: 'LicenseTypeForm',
    components: { ActionButtons },
    props: {
        model: {
            type: Object
        }
    },
    mixins: [errorMixin, saveMixin, validationMixin],
    data() {
        return {
            storeModule: 'license_type',
            form: {
                active: true,
                code: '',
                name: '',
                product: '',
                data: {
                    subjects: [],
                    grade_groups: '',
                    group_students: '',
                    total_students: '',
                    include_excepted_options: false,
                    duration: {
                        value: 1,
                        unit: 'year'
                    }
                }
            }
        };
    },
    computed: {
        ...mapState('license_type', ['subjects']),
        ...mapState('product', {products: 'items'}),
        ...mapGetters('license_type', ['grades']),
        duration() {
            return {
                values: _.map(_.keys(Array(10)), (i) => {
                    i = _.parseInt(i);
                    return {
                        value: i + 1,
                        text: i + 1
                    };
                }),
                units: [{
                    value: 'day',
                    text: 'дн.'
                },{
                    value: 'month',
                    text: 'мес.'
                },{
                    value: 'year',
                    text: 'г.'
                }]
            }
        },
        allSubjectsSelected() {
            return this.form.data.subject === '*';
        }
    },
    validations() {
        const STRING_FIELD_MIN_LENGTH = 2;
        return {
            form: {
                code: {
                    required,
                    minLength: minLength(STRING_FIELD_MIN_LENGTH),
                    pattern: function(value) {
                        return /^([a-zA-Z]+)([a-zA-Z0-9_-]*)$/.test(value);
                    }
                },
                name: {
                    required,
                    minLength: minLength(STRING_FIELD_MIN_LENGTH)
                },
                product: {
                    required
                },
                data: {
                    subjects: {
                        required,
                        pattern: function(value) {
                            let bAnySubjectSelected = _.includes(_.map(value, 'selected'), true);
                            let bSubjectAnyGradeSelected = !_.includes(_.map(_.filter(value, {selected: true}), s => {
                                return _.includes(_.map(s.grades, 'selected'), true);
                            }), false);
                            return bAnySubjectSelected && bSubjectAnyGradeSelected;
                        }
                    },
                    grade_groups: {
                        pattern: function(value) {
                            return _.toString(value).length > 0 ? /^\d*$/gi.test(value) && _.parseInt(value) > 0 : true;
                        }
                    },
                    group_students: {
                        pattern: function(value) {
                            return _.toString(value).length > 0 ? /^\d*$/gi.test(value) && _.parseInt(value) > 0 : true;
                        }
                    },
                    total_students: {
                        pattern: function(value) {
                            return _.toString(value).length > 0 ? /^\d*$/gi.test(value) && _.parseInt(value) > 0 : true;
                        }
                    },
                    duration: {
                        value: {
                            required,
                            pattern: function(value) {
                                return value >= 1 && value <= 10;
                            }
                        },
                        unit: {
                            required
                        }
                    }
                }
            }
        }
    },
    methods: {
        prepareForm(form) {
            let nForm = _.cloneDeep(form);
            let subjects = {};
            _.map(_.filter(nForm.data.subjects, {selected: true}), s => {
                _.map(_.filter(s.grades, {selected: true}), g => {
                    _.setWith(subjects, `${s.value}.${g.value}`, g.options, Object);
                });
            });
            nForm.data.subjects = subjects;
            return nForm;
        },
        initForm(subjects) {
            return _.map(_.filter(this.subjects, s => s.value !== '*'), o => {
                return {
                    selected: _.has(subjects, o.value),
                    value: o.value,
                    grades: _.map(o.grades, grade => {
                        return {
                            selected: _.has(subjects, `${o.value}.${grade}`),
                            value: grade,
                            options: _.get(subjects, `${o.value}.${grade}`, 1)
                        }
                    })
                }
            })
        },
        // convertModelToForm(model) {

        // },
        // convertFormToModel(form) {

        // },
        getMaxOption() {
            return MAX_OPTION;
        },
        selectAllSubjectGrades(subject, all_options = false, options = 0) {
            subject.grades = _.map(subject.grades, g => {
                g.selected = true;
                if(all_options)
                    g.options = '*';
                if(options > 0)
                    g.options = options;
                return g;
            });
        },
        onGradeAllOptionsChange(grade) {
            grade.options = grade.options === '*' ? 1 : '*';
        }
    },  
    created() {
        this.$set(this.form.data, 'subjects', this.initForm(_.get(this.model, 'data.subjects', null)));
    }
}
</script>
<style lang="scss">
    .license-type-form{
        .inactive{
            opacity: .35;
            pointer-events: none!important;
        }
        .v-input--checkbox.v-input--selection-controls.dense{
            margin: 0;
            padding: 0;
        }
        .v-subheader{
            height: auto;
        }
    }
</style>